<template>
  <Layout
    :title="success ? $t('segmentation.success') : $t('segmentation.fail')"
  >
    <div :class="prefixCls">
      <!--   鉴定结果   -->
      <div :class="prefixCls + '__result'" v-if="success">
        <p :class="prefixCls + '__filename'" v-if="filename">
          {{ $t('segmentation.head', { msg: filename }) }}
        </p>
        <!--   音频展示   -->
        <HaiPlayer
          :url="data.source_url"
          :text="$t('segmentation.origin')"
          bar
        ></HaiPlayer>
        <HaiPlayer
          :url="data.vocal_url"
          :text="$t('segmentation.vocals')"
          bar
        ></HaiPlayer>
        <HaiPlayer
          :url="data.inst_url"
          :text="$t('segmentation.accompany')"
          bar
        ></HaiPlayer>
      </div>
      <!--    无结果    -->
      <div :class="prefixCls + '__empty'" v-else>
        <h2>{{ $t('segmentation.failReason') }}</h2>
        <h5>{{ $t('segmentation.possibleReason') }}</h5>
        <p>{{ $t('segmentation.reason1') }}</p>
        <p>{{ $t('segmentation.reason2') }}</p>
      </div>
      <div :class="prefixCls + '__footer'">
        <HaiButton @click="$router.back()" v-if="!success">{{
          $t('tryAgain')
        }}</HaiButton>
        <HaiButton @click="$router.push({ path: '/' })">{{
          $t('backHome')
        }}</HaiButton>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '@/components/Layout'
import HaiButton from '@/components/HaiButton'
import HaiPlayer from '@/components/HaiPlayer'

const prefixCls = 'segmentation-result-page'
export default {
  name: 'SegmentationResult.',
  components: {
    Layout,
    HaiButton,
    HaiPlayer
  },

  data() {
    return {
      prefixCls,
      success: true,
      filename: '',
      data: {
        source_url: '',
        inst_url: '',
        vocal_url: ''
      }
    }
  },

  mounted() {
    this.getData()
  },

  methods: {
    getData() {
      try {
        const data = window.sessionStorage.getItem('segmentation_url')
        this.filename = JSON.parse(
          window.sessionStorage.getItem('upload_filename')
        )
        const result = JSON.parse(data)
        this.data = result.result_dict.data
        if (!this.data.source_url) {
          this.success = false
        }
      } catch (e) {
        this.success = false
      }
    }
  }
}
</script>

<style lang="scss">
$prefixCls: 'segmentation-result-page';
.#{$prefixCls} {
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  &__filename {
    flex-shrink: 0;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.34);
    position: absolute;
    left: 50%;
    top: 50px;
    transform: translateX(-50%);
  }
  &__result {
    width: 70%;
    padding-top: 160px;
    .hai-player {
      margin-bottom: 5.55vh;
      .hai-player__type {
        width: 260px;
      }
      &:nth-of-type(1) {
        .hai-player__type {
          span {
            color: #68e7ed;
            border-color: #68e7ed;
          }
        }
        .hai-player__line_2 {
          background-color: #68e7ed;
        }
        .hai-player__line_ball {
          background: #68e7ed;
        }
      }
      &:nth-of-type(2) {
        .hai-player__type {
          span {
            color: #5391f9;
            border-color: #5391f9;
          }
        }
        .hai-player__line_2 {
          background-color: #5391f9;
        }
        .hai-player__line_ball {
          background: #5391f9;
        }
      }
      &:nth-of-type(3) {
        .hai-player__type {
          span {
            color: #2e60b3;
            border-color: #2e60b3;
          }
        }
        .hai-player__line_2 {
          background-color: #2e60b3;
        }
        .hai-player__line_ball {
          background: #2e60b3;
        }
      }
    }
  }
  &__empty {
    width: 600px;
    margin-top: 170px;
    padding: 20px;
    color: #68e7ed;
    background-color: #143045;
    border-top: 1px #fff solid;
    h2 {
      font-size: 24px;
      text-align: center;
      margin-bottom: 20px;
    }
    p,
    h5 {
      font-size: 16px;
      text-align: left;
      line-height: 32px;
    }
  }
  &__footer {
    margin-bottom: 70px;
    .hai-button:nth-child(1) {
      margin-right: 28px;
      span {
        color: #fff;
      }
    }
  }
}
</style>
